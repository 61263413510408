import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useForm} from 'react-hook-form'
import Modal from 'react-bootstrap/Modal'

const ChangePasswordForm = ({actions, userName}) => {

    const {register, getValues, handleSubmit, errors} = useForm({mode: 'onBlur'})

    const onChangePasswordFormSubmit = (input, e) => {
        actions.submit(input.currentPasswrd, input.newPasswrd)
    }

    return (
        <div id='changePasswordForm'>
            <Modal centered show={true} backdrop="static" onHide={actions.cancel}>
                {/*<Form onSubmit={handleSubmit(onChangePasswordFormSubmit)}>*/}
                <Form id="changePasswordForm">
                    <Modal.Header>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group controlId="userName">
                            <Form.Label className="boldFont">User Name</Form.Label>
                            <Form.Control readOnly defaultValue={userName}/>
                        </Form.Group>


                        <Form.Group controlId="currentPasswrd">
                            <Form.Label className="boldFont">Old Password</Form.Label>
                            <Form.Control type="password" name="currentPasswrd" ref={register({required: true})}
                                          placeholder="Password"/>
                        </Form.Group>
                        {errors.currentPasswrd && <p className="error">Old Password is required</p>}

                        <Form.Group controlId="newPasswrd">
                            <Form.Label className="boldFont">New Password</Form.Label>
                            <Form.Control type="password" name="newPasswrd"
                                          ref={register({
                                              required: 'New Password is required',
                                              validate: {
                                                  validateNewPassword: (value) => {
                                                      return value.indexOf(" ") < 0 || 'Spaces are not allowed in New Password'
                                                  }
                                              }
                                          })}
                                          placeholder="Password"/>
                        </Form.Group>
                        {errors.newPasswrd && <p className="error">{errors.newPasswrd.message}</p>}

                        <Form.Group controlId="verifyNewPassword">
                            <Form.Label className="boldFont">Verify New Password</Form.Label>
                            <Form.Control type="password" name="verifyNewPassword"
                                          ref={register({
                                              required: 'Verify New Password is required',
                                              validate: {
                                                  validateVerifyPassword: (value) => {
                                                      const {newPasswrd} = getValues()
                                                      if (newPasswrd === value && value.indexOf(" ") < 0) {
                                                          return true
                                                      } else if (newPasswrd !== value) {
                                                          return 'New and Verify New Password should match'
                                                      } else if (value.indexOf(" ") > 0) {
                                                          return 'Spaces are not allowed in Verify New Password'
                                                      }

                                                  }

                                              }

                                          })}
                                          placeholder="Password"/>
                        </Form.Group>
                        {errors.verifyNewPassword && <p className="error">{errors.verifyNewPassword.message}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        {/* The reason to use onClick instead of form submit is to get around event propagation */}
                        {/* Otherwise submitting a password change would trigger submitting saving user profile  */}
                        {/* Now with react-hook-forms in between, calling e.stopPropagation does not work in our  */}
                        {/* code (onChangePasswordFormSubmit handler) since it is too late. onClick works perfectly*/}
                        {/*<Button type="submit" value="Submit" >Submit</Button>*/}
                        <Button variant="primary" value="Submit"
                                onClick={handleSubmit(onChangePasswordFormSubmit)}>Submit</Button>
                        <Button variant="secondary" onClick={actions.cancel}>Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default ChangePasswordForm